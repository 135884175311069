<template>
  <gf-content
    title="Stock Activity"
    subtitle="List of activity of variant"
    overflow
  >
    <template #toolbar>
      <el-date-picker
        format="dd MMM yyyy"
        value-format="yyyy-MM-dd"
        size="small"
        type="daterange"
        start-placeholder="Start Date"
        end-placeholder="End Date"
        v-model="dateRange"
        @change="getStockActivity"
      ></el-date-picker>
    </template>
    <StockDate :data="stockActivities" />
  </gf-content>
</template>

<style lang="scss" scoped>
.container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 0;
  overflow: auto;
}
</style>

<script>
// Services
import StockActivityService from '@/services/v1/StockActivity'

// Components
import StockDate from './components/StockDate'

export default {
  components: {
    StockDate
  },
  data() {
    return {
      dateRange: [],
      stockActivities: {}
    }
  },
  methods: {
    async getStockActivity() {
      try {
        const productId = this.$route.params.id
        const variantId = this.$route.params.vid
        const saService = new StockActivityService(productId, variantId)

        const query = {
          from: this.dateRange[0],
          to: this.dateRange[1]
        }

        const response = await saService.get(query)
        this.stockActivities = response.data.rows
      } catch (error) {
        this.$error500()
      }
    }
  },
  created() {
    this.dateRange[0] = this.$DateTime
      .local()
      .minus({ days: 7 })
      .toISODate()
    this.dateRange[1] = this.$DateTime.local().toISODate()
  },
  mounted() {
    this.$emit('update:active', 3)
    this.getStockActivity()
  }
}
</script>
