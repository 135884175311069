<template>
  <div>
    <div v-for="(date, key) in dates" :key="key">
      <span class="font-weight-normal font-size-sm">{{
        key === $DateTime.local().toISODate()
          ? 'Today'
          : $DateTime.fromISO(key).toLocaleString($DateTime.DATE_FULL)
      }}</span>
      <StockItem :data="date" />
    </div>
  </div>
</template>

<script>
import StockItem from './StockItem'

export default {
  props: {
    data: Object
  },
  computed: {
    dates() {
      const arr = {}

      for (const key in this.data) {
        if (this.data[key].length > 0) {
          arr[key] = this.data[key]
        }
      }

      return arr
    }
  },
  components: {
    StockItem
  }
}
</script>
