<template>
  <div>
    <div v-for="item in data" :key="item.id" class="stock-item">
      <div class="icon">
        <span
          v-if="
            item.type === 1 ||
              item.type === 2 ||
              item.type === 5 ||
              item.type === 6 ||
              (item.type === 7 && item.quantity > 0) ||
              item.type === 10
          "
          class="icon--add"
          ><i class="el-icon-plus"></i
        ></span>
        <span v-else class="icon--minus"><i class="el-icon-minus"></i></span>
      </div>
      <div class="description">
        <span v-if="item.type === 1"
          >{{ item.quantity }} - Initial stock added to
          <b>{{ item.warehouse.name }}</b> by
          <b>{{ item.user.fname }} {{ item.user.lname }}</b></span
        >
        <span v-else-if="item.type === 2"
          >Received {{ item.quantity }} in <b>{{ item.po.po_number }}</b> from
          <b>{{ item.supplier.name }}</b> to {{ item.warehouse.name }} by
          {{ item.user.fname }} {{ item.user.lname }}</span
        >
        <span v-else-if="item.type === 3"
          >Fulfilled {{ item.quantity }} in <b>{{ item.so.so_number }}</b> for
          <b>{{ item.customer.name }}</b> from {{ item.warehouse.name }} by
          {{ item.user.fname }} {{ item.user.lname }}</span
        >
        <span v-else-if="item.type === 4"
          >Voided {{ item.quantity }} in <b>{{ item.po.po_number }}</b> from
          <b>{{ item.supplier.name }}</b> to {{ item.warehouse.name }} by
          {{ item.user.fname }} {{ item.user.lname }}</span
        >
        <span v-else-if="item.type === 5"
          >Voided {{ item.quantity }} in <b>{{ item.so.so_number }}</b> for
          <b>{{ item.customer.name }}</b> from {{ item.warehouse.name }} by
          {{ item.user.fname }} {{ item.user.lname }}</span
        >
        <span v-else-if="item.type === 6"
          >Increased {{ item.quantity }} in <b>{{ item.st.st_number }}</b> from
          {{ item.warehouse.name }} to {{ item.location.name }} by
          {{ item.user.fname }} {{ item.user.lname }}</span
        >
        <span v-else-if="item.type === 7">
          <span v-if="item.quantity > 0">Increased</span>
          <span v-else>Decreased</span>
          {{ $NumberFormat(Math.abs(item.quantity)) }} in
          <b>{{ item.sa.sa_number }}</b> - {{ item.sa.reason }} by
          {{ item.user.fname }} {{ item.user.lname }}
        </span>
        <span v-else-if="item.type === 8"
          >Decreased {{ item.quantity }} in <b>{{ item.sa.sa_number }}</b> -
          {{ item.sa.reason }} by {{ item.user.fname }}
          {{ item.user.lname }}</span
        >
        <span v-else-if="item.type === 9"
          >Decreased {{ item.quantity }} in manufacturing of
          {{ item.product.name }} by {{ item.user.fname }}
          {{ item.user.lname }}</span
        >
        <span v-else-if="item.type === 10"
          >Increased {{ item.quantity }} in adjustment of manufacturing of
          {{ item.product.name }} by {{ item.user.fname }}
          {{ item.user.lname }}</span
        >
      </div>
      <div class="time">
        <span>{{
          $DateTime
            .fromISO(item.created_at)
            .toLocaleString($DateTime.TIME_SIMPLE)
        }}</span>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.stock-item {
  padding: 10px 0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f4f4f4;

  .icon {
    padding: 0 5px;

    &--add {
      color: #67c23a;
    }

    &--minus {
      color: #f56c6c;
    }
  }

  .description {
    flex-grow: 1;

    b {
      font-weight: 500;
    }
  }

  .description,
  .time {
    font-size: 14px;
  }
}
</style>

<script>
export default {
  props: {
    data: Array
  }
}
</script>
